<template>
  <BasePageContent>
    <CustomerUpdate
      v-if="true"
      tabbed
      :uuid="uuid"
      @onsave="OnSave"
      @oncancel="OnCancel"
      cancel-text="Cancel"
    />
  </BasePageContent>
</template>

<script>
import CustomerUpdate from "@/components/ui/customer/CustomerUpdate.vue";
// import { useRouter } from "vue-router";
export default {
  components: { CustomerUpdate },
  props: {
    uuid: {
      type: String,
    },
  },
  setup() {
    const OnSave = () => {
      // router.replace({ name: "customer-detail", params: { uuid: props.uuid } });
    };
    // const router = useRouter();
    const OnCancel = () => {
      // router.replace({ name: "customer-detail", params: { uuid: props.uuid } });
    };
    return {
      OnSave,
      OnCancel,
    };
  },
};
</script>